import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: 'Targeted Advertising',
    description:
      'Reach your ideal audience with precision and efficiency using Adnova\'s targeted advertising solutions. Leverage advanced targeting options to connect with users based on demographics, interests, behaviors, and more across multiple digital channels.',
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: 'Brand Awareness',
    description:
      'Elevate your brand\'s visibility and awareness with Adnova\'s brand awareness solutions. Utilize strategic ad placements, engaging creative content, and effective storytelling techniques to capture the attention of your target audience and leave a lasting impression.',
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Lead Generation',
    description:
      'Drive qualified leads and conversions for your business with Adnova\'s lead generation solutions. Leverage data-driven strategies, optimized landing pages, and compelling call-to-action messages to attract and capture the interest of potential customers at every stage of the buyer\'s journey.',
  },
  {
    icon: <AutoFixHighRoundedIcon />,
    title: 'Conversion Optimization',
    description:
      'Maximize the effectiveness of your marketing efforts and increase your conversion rates with Adnova\'s conversion optimization solutions. Implement A/B testing, website optimization techniques, and personalized messaging to optimize your conversion funnel and turn more prospects into customers.',
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: 'Social Media Management',
    description:
      'Streamline your social media marketing efforts and effectively manage your brand\'s online presence with Adnova\'s social media management solutions. From content creation and scheduling to engagement tracking and performance analytics, Adnova provides the tools and insights you need to succeed on social media.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Data Analytics',
    description:
      'Harness the power of data to drive informed decision-making and achieve measurable results with Adnova\'s data analytics solutions. Gain valuable insights into your audience\'s behavior, campaign performance, and market trends to optimize your marketing strategy and maximize your ROI.',
  },
];

export default function Solutions() {
  return (
    <Box
      id="solutions"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'grey.900',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
            Solutions
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            At Adnova, we're committed to providing comprehensive solutions that address the diverse needs of modern businesses.
            Explore our range of tailored solutions below:
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: '100%',
                  borderColor: 'hsla(220, 25%, 25%, 0.3)',
                  backgroundColor: 'grey.800',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
