import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export default function Products() {
    return (
        <Container id="about-us" sx={{ py: { xs: 8, sm: 16 } }}>
            <Box sx={{ width: { sm: '100%', md: '60%' } }}>
                <Typography
                    component="h2"
                    variant="h4"
                    gutterBottom
                    sx={{ color: 'text.primary' }}
                >
                    About Us
                </Typography>
                <Typography
                    variant="body1"
                    sx={{ color: 'text.secondary', mb: { xs: 2, sm: 4 } }}
                >
                    Welcome to Adnova, your go-to partner for digital marketing success.
                    Founded with a vision to revolutionize the way businesses connect with their audiences online, Adnova is committed to delivering innovative solutions that drive real results.
                </Typography>

                <Typography
                    component="h2"
                    variant="h4"
                    gutterBottom
                    sx={{ color: 'text.primary' }}
                >
                    Our Story
                </Typography>
                <Typography
                    variant="body1"
                    sx={{ color: 'text.secondary', mb: { xs: 2, sm: 4 } }}
                >
                    At Adnova, we believe in the power of digital marketing to transform businesses. Our journey began with a simple idea: to empower businesses of all sizes to thrive in the digital age.
                    With a team of passionate experts and a dedication to excellence, we set out to make our vision a reality.
                </Typography>

                <Typography
                    component="h2"
                    variant="h4"
                    gutterBottom
                    sx={{ color: 'text.primary' }}
                >
                    Our Mission
                </Typography>
                <Typography
                    variant="body1"
                    sx={{ color: 'text.secondary', mb: { xs: 2, sm: 4 } }}
                >
                    Our mission is to empower businesses to succeed online through cutting-edge digital marketing solutions. We're committed to delivering exceptional results for our clients, driving measurable impact, and helping businesses achieve their goals.
                </Typography>
            </Box>
        </Container>
    );
}
