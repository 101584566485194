import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
// import LogoCollection from './components/LogoCollection';
import Solutions from './components/Solutions';
import Pricing from './components/Pricing';
import Products from './components/Products';
// import Testimonials from './components/Testimonials';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import AppTheme from './theme/AppTheme';
import AboutUs from './components/AboutUs';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import PrivacyPolicy from './components/PrivacyPolicy';

export default function App(props: { disableCustomTheme?: boolean }) {
  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <Router>
        <Routes>
          {/* Main Page Route */}
          <Route path="/" element={
            <>
              <AppAppBar />
              <Hero />
              <div>
                {/* <LogoCollection /> */}
                <Products />
                <Divider />
                <Solutions />
                <Divider />
                {/* <Pricing /> */}
                <Divider />
                <FAQ />
                <Divider />
                <AboutUs />
                <Divider />
                <Footer />
              </div>
            </>
          } />

          {/* Other Page Routes */}
          { 
            // <Route path="/sign-in" element={<SignIn />} />
            // <Route path="/sign-up" element={<SignUp />} /> 
          }
          {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}
          
        </Routes>
      </Router>
    </AppTheme>
  );
}
